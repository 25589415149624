var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "el-loading--maia",
      attrs: {
        id: "check2D",
        "element-loading-spinner": "el-icon-lock",
        "element-loading-text": _vm.loadingTxt
      }
    },
    [
      _c(
        "div",
        { staticClass: "operConsole" },
        [
          _c(
            "el-form",
            { ref: "selectForm", attrs: { model: _vm.selectForm, inline: "" } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "阶段" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "el-select--maia",
                      attrs: { filterable: "", size: "small" },
                      on: { change: _vm.stageChange },
                      model: {
                        value: _vm.selectForm.stage,
                        callback: function($$v) {
                          _vm.$set(_vm.selectForm, "stage", $$v)
                        },
                        expression: "selectForm.stage"
                      }
                    },
                    [
                      _c("el-option", {
                        key: "a",
                        attrs: { label: "全部", value: "" }
                      }),
                      _vm._l(_vm.form.stageList, function(item, index) {
                        return _c("el-option", {
                          key: index + "",
                          attrs: {
                            label: item.folderName,
                            value: item.folderId
                          }
                        })
                      })
                    ],
                    2
                  )
                ],
                1
              ),
              !!_vm.selectForm.stage
                ? _c(
                    "el-form-item",
                    { attrs: { label: "版本" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "el-select--maia",
                          attrs: { filterable: "", size: "small" },
                          on: { change: _vm.versionChange },
                          model: {
                            value: _vm.selectForm.version,
                            callback: function($$v) {
                              _vm.$set(_vm.selectForm, "version", $$v)
                            },
                            expression: "selectForm.version"
                          }
                        },
                        [
                          _c("el-option", {
                            key: "b",
                            attrs: { label: "全部", value: "" }
                          }),
                          _vm._l(_vm.form.versionList, function(item, index) {
                            return _c("el-option", {
                              key: index + "",
                              attrs: {
                                label: item.versionName,
                                value: item.versionID
                              }
                            })
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                : _vm._e(),
              !!_vm.selectForm.version
                ? _c(
                    "el-form-item",
                    { attrs: { label: "目录:" } },
                    [
                      _c(
                        "el-popover",
                        {
                          ref: "treePopover",
                          attrs: {
                            "popper-class": "kt-select-tree-popover",
                            placement: "bottom-start",
                            trigger: "click"
                          }
                        },
                        [
                          _c("el-select", {
                            ref: "treeSelect",
                            style: { width: "190px" },
                            attrs: {
                              slot: "reference",
                              size: "mini",
                              "popper-class": "kt-select-tree-option",
                              "popper-append-to-body": false,
                              multiple: "",
                              clearable: "",
                              "collapse-tags": _vm.collapseTags,
                              placeholder: "请选择文件目录"
                            },
                            on: {
                              change: _vm.onChange,
                              "remove-tag": _vm.onRemoveTag,
                              clear: _vm.onClear
                            },
                            slot: "reference",
                            model: {
                              value: _vm.labelValue,
                              callback: function($$v) {
                                _vm.labelValue = $$v
                              },
                              expression: "labelValue"
                            }
                          }),
                          _c(
                            "div",
                            [
                              _c("el-tree", {
                                ref: "channelTree",
                                staticClass: "kt-select-tree",
                                staticStyle: { "min-width": "200px" },
                                attrs: {
                                  "check-strictly": "",
                                  data: _vm.fileTreeData,
                                  load: _vm.loadNode,
                                  lazy: "",
                                  props: _vm.treeDataProps,
                                  "node-key": _vm.treeDataProps.id,
                                  "expand-on-click-node": "",
                                  "default-expanded-keys":
                                    _vm.defaultExpandedKeys,
                                  "default-checked-keys":
                                    _vm.defaultCheckedKeys,
                                  "show-checkbox": "",
                                  "filter-node-method": _vm.onTreeFilter
                                },
                                on: {
                                  "node-click": _vm.onNodeClick,
                                  check: _vm.onCheck,
                                  "check-change": _vm.checkChange
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function(ref) {
                                        var node = ref.node
                                        var data = ref.data
                                        return _c(
                                          "span",
                                          {
                                            staticClass: "kt-select-tree-label"
                                          },
                                          [
                                            _vm._t(
                                              "default",
                                              function() {
                                                return [
                                                  _vm._v(_vm._s(node.label))
                                                ]
                                              },
                                              { node: node, data: data }
                                            )
                                          ],
                                          2
                                        )
                                      }
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "operBtn" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "maiaPlain", size: "small" },
                  on: { click: _vm.openDialogKanban }
                },
                [_vm._v("问题统计")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "maiaPlain", size: "small" },
                  on: { click: _vm.openBottomMode }
                },
                [_vm._v("交底模式")]
              ),
              _c(
                "el-dropdown",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: { "hide-on-click": false },
                  on: { command: _vm.handleCommand }
                },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "maiaPlain", size: "small" } },
                    [
                      _vm._v("批量操作"),
                      _c("i", {
                        staticClass: "el-icon-arrow-down el-icon--right"
                      })
                    ]
                  ),
                  _c(
                    "el-dropdown-menu",
                    { attrs: { slot: "dropdown" }, slot: "dropdown" },
                    [
                      _c("el-dropdown-item", { attrs: { command: "a" } }, [
                        _vm._v("批量导出")
                      ]),
                      _c(
                        "el-dropdown-item",
                        { attrs: { command: "b", disabled: !_vm.isNewList } },
                        [_vm._v("批量审核")]
                      ),
                      _c(
                        "el-dropdown-item",
                        { attrs: { command: "c", disabled: !_vm.isNewList } },
                        [_vm._v("批量指出修改方")]
                      ),
                      _c(
                        "el-dropdown-item",
                        { attrs: { command: "d", disabled: !_vm.isNewList } },
                        [_vm._v("批量完成时间")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loadingDataBoolean,
              expression: "loadingDataBoolean"
            }
          ],
          staticClass: "el-loading--maia table2D",
          attrs: { id: "table2D", "element-loading-text": "问题数据获取中..." }
        },
        [
          _c(
            "el-table",
            {
              ref: "multipleTable",
              staticClass: "is-grey",
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tabData,
                "highlight-current-row": "",
                size: "medium",
                "row-key": "questionId",
                "cell-class-name": _vm.deleteLine
              },
              on: {
                "selection-change": _vm.handleSelectionChange,
                "row-click": _vm.openDetails,
                "cell-click": _vm.cellClickgetCreateUserName,
                "filter-change": _vm.handleFilterChange
              }
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "selection",
                  width: "50",
                  "reserve-selection": true
                }
              }),
              _c("el-table-column", {
                attrs: {
                  label: "问题状态",
                  align: "center",
                  prop: "queStatus",
                  "min-width": "110",
                  "column-key": "queStatus",
                  filters: _vm.filtrate.queStatusFilters
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "div",
                          { staticClass: "statusAction" },
                          [
                            _c("span", {
                              staticClass: "statusCircle",
                              class: "color" + scope.row.queStatusId
                            }),
                            _c("span", [_vm._v(_vm._s(scope.row.queStatus))]),
                            _c(
                              "el-popover",
                              {
                                key: scope.row.questionId,
                                ref: "popover" + scope.row.questionId,
                                attrs: {
                                  trigger: "click",
                                  disabled:
                                    scope.row.isDel == "1" ||
                                    scope.row.fileIsDel == "0",
                                  placement: "bottom"
                                }
                              },
                              [
                                _c("div", { staticClass: "selectStatus" }, [
                                  _c(
                                    "ul",
                                    _vm._l(_vm.dicArray.statusList, function(
                                      item
                                    ) {
                                      return _c(
                                        "li",
                                        {
                                          key: item.id,
                                          class: {
                                            disabled:
                                              (scope.row.queStatus == "无效" ||
                                                scope.row.queStatus ==
                                                  "重复") &&
                                              (item.id == "22" ||
                                                item.id == "23" ||
                                                item.id == "24")
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.changeStatus(
                                                item.id,
                                                scope.row.questionId
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("span", {
                                            staticClass: "statusCircle",
                                            class: "color" + item.id
                                          }),
                                          _vm._v(
                                            " " + _vm._s(item.cnname) + " "
                                          )
                                        ]
                                      )
                                    }),
                                    0
                                  )
                                ]),
                                _vm.isNewList
                                  ? _c("i", {
                                      staticClass: "el-icon-arrow-down",
                                      staticStyle: {
                                        "margin-left": "5px",
                                        cursor: "pointer"
                                      },
                                      attrs: { slot: "reference" },
                                      on: {
                                        click: function($event) {
                                          $event.stopPropagation()
                                        }
                                      },
                                      slot: "reference"
                                    })
                                  : _vm._e()
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "specName",
                  label: "专业",
                  "column-key": "specName",
                  "show-overflow-tooltip": "",
                  filters: _vm.filtrate.specNameFilters
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "queTypeName",
                  label: "问题类型",
                  "column-key": "queTypeName",
                  filters: _vm.filtrate.queTypeNameFilters,
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "modifyPartyName",
                  label: "修改方",
                  "column-key": "modifyPartyName",
                  filters: _vm.filtrate.modifyPartyNameFilters,
                  "show-overflow-tooltip": ""
                }
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "createUserName",
                  label: "审查人",
                  "column-key": "createUserName",
                  filters: _vm.filtrate.createUserNameFilters,
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var row = ref.row
                      return [
                        _c("span", { staticClass: "checkUserName" }, [
                          _vm._v(_vm._s(row.createUserName))
                        ])
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "createTime",
                  label: "创建时间",
                  align: "center",
                  "min-width": "110",
                  "column-key": "createUserName",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function() {
                      return [
                        _c(
                          "el-popover",
                          {
                            key: "tabData",
                            ref: "timePopover",
                            attrs: {
                              trigger: "click",
                              placement: "bottom",
                              "popper-class": "timePopover"
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "timeSelect" },
                              [
                                _c("el-date-picker", {
                                  attrs: {
                                    size: "small",
                                    "value-format": "yyyy-MM-dd",
                                    type: "date",
                                    placeholder: "开始时间"
                                  },
                                  on: { change: _vm.checkdata },
                                  model: {
                                    value: _vm.selectForm.startCreateTime,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.selectForm,
                                        "startCreateTime",
                                        $$v
                                      )
                                    },
                                    expression: "selectForm.startCreateTime"
                                  }
                                }),
                                _c("el-date-picker", {
                                  staticStyle: { "margin-top": "5px" },
                                  attrs: {
                                    size: "small",
                                    "value-format": "yyyy-MM-dd",
                                    type: "date",
                                    placeholder: "结束时间"
                                  },
                                  on: { change: _vm.checkdata },
                                  model: {
                                    value: _vm.selectForm.endCreateTime,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.selectForm,
                                        "endCreateTime",
                                        $$v
                                      )
                                    },
                                    expression: "selectForm.endCreateTime"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "el-table-filter__bottom timeBottom"
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    class:
                                      !_vm.selectForm.startCreateTime &&
                                      !_vm.selectForm.endCreateTime
                                        ? "is-disabled"
                                        : "",
                                    on: {
                                      click: function($event) {
                                        return _vm.handleFilterChangeCreateTime(
                                          0
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("筛选")]
                                ),
                                _c(
                                  "button",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.handleFilterChangeCreateTime(
                                          1
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("重置")]
                                )
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "popTime",
                                attrs: { slot: "reference" },
                                slot: "reference"
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    class: {
                                      highlight:
                                        !!_vm.selectForm.startCreateTime &&
                                        !!_vm.selectForm.endCreateTime
                                    }
                                  },
                                  [_vm._v("创建时间")]
                                ),
                                _c("i", {
                                  staticClass: "el-icon-arrow-down",
                                  staticStyle: {
                                    "margin-left": "5px",
                                    cursor: "pointer"
                                  }
                                })
                              ]
                            )
                          ]
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "requireFinishTime",
                  label: "完成时间",
                  align: "center",
                  "column-key": "createUserName",
                  "show-overflow-tooltip": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "header",
                    fn: function() {
                      return [
                        _c(
                          "el-popover",
                          {
                            key: "tabData",
                            ref: "timePopoverFin",
                            attrs: {
                              trigger: "click",
                              placement: "bottom",
                              "popper-class": "timePopover"
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "timeSelect" },
                              [
                                _c("el-date-picker", {
                                  attrs: {
                                    size: "small",
                                    "value-format": "yyyy-MM-dd",
                                    type: "date",
                                    placeholder: "开始时间"
                                  },
                                  on: { change: _vm.checkdataFin },
                                  model: {
                                    value: _vm.selectForm.startFinishTime,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.selectForm,
                                        "startFinishTime",
                                        $$v
                                      )
                                    },
                                    expression: "selectForm.startFinishTime"
                                  }
                                }),
                                _c("el-date-picker", {
                                  staticStyle: { "margin-top": "5px" },
                                  attrs: {
                                    size: "small",
                                    "value-format": "yyyy-MM-dd",
                                    type: "date",
                                    placeholder: "结束时间"
                                  },
                                  on: { change: _vm.checkdataFin },
                                  model: {
                                    value: _vm.selectForm.endFinishTime,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.selectForm,
                                        "endFinishTime",
                                        $$v
                                      )
                                    },
                                    expression: "selectForm.endFinishTime"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "el-table-filter__bottom timeBottom"
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    class:
                                      !_vm.selectForm.startFinishTime &&
                                      !_vm.selectForm.endFinishTime
                                        ? "is-disabled"
                                        : "",
                                    on: {
                                      click: function($event) {
                                        return _vm.handleFilterChangefinishTime(
                                          0
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("筛选")]
                                ),
                                _c(
                                  "button",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.handleFilterChangefinishTime(
                                          1
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("重置")]
                                )
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "popTime",
                                attrs: { slot: "reference" },
                                slot: "reference"
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    class: {
                                      highlight:
                                        !!_vm.selectForm.startFinishTime &&
                                        !!_vm.selectForm.endFinishTime
                                    }
                                  },
                                  [_vm._v("完成时间")]
                                ),
                                _c("i", {
                                  staticClass: "el-icon-arrow-down",
                                  staticStyle: {
                                    "margin-left": "5px",
                                    cursor: "pointer"
                                  }
                                })
                              ]
                            )
                          ]
                        )
                      ]
                    },
                    proxy: true
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "deadlineNumber",
                  label: "完成状态",
                  align: "center",
                  "show-overflow-tooltip": "",
                  "column-key": "deadlineNumberFilters",
                  filters: _vm.filtrate.deadlineNumberFilters
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        scope.row.deadlineNumber == "0"
                          ? _c(
                              "p",
                              {
                                staticClass: "deadlineNumber",
                                staticStyle: { color: "#5587f0" }
                              },
                              [_vm._v(" 正常 ")]
                            )
                          : _vm._e(),
                        scope.row.deadlineNumber == "1"
                          ? _c(
                              "p",
                              {
                                staticClass: "deadlineNumber",
                                staticStyle: { color: "#e6ae86" }
                              },
                              [_vm._v(" 即将逾期 ")]
                            )
                          : _vm._e(),
                        scope.row.deadlineNumber == "2"
                          ? _c(
                              "p",
                              {
                                staticClass: "deadlineNumber",
                                staticStyle: { color: "#FF0000" }
                              },
                              [_vm._v(" 已逾期 ")]
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "queSource",
                  label: "问题来源",
                  "column-key": "queSource",
                  filters: _vm.filtrate.queSourceFilters,
                  "show-overflow-tooltip": ""
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "page" },
        [
          _c("el-pagination", {
            attrs: {
              background: true,
              "current-page": _vm.page.pageNo,
              "page-sizes": _vm.page.pageSizes,
              "page-size": _vm.page.pageSize,
              "pager-count": _vm.page.pagerCount,
              layout: _vm.page.layout,
              total: _vm.page.totalCount
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "el-dialog--maia",
          attrs: {
            title: _vm.batchTitle,
            visible: _vm.dialogVisible,
            width: "600px",
            "before-close": _vm.cancelBatch
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogVisible = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "maia-content", class: { maT: _vm.command == "a" } },
            [
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.command != "a",
                      expression: "command !='a'"
                    }
                  ],
                  staticClass: "notice"
                },
                [
                  _c("i", { staticClass: "el-icon-warning iconPrompt" }),
                  _vm._v(" 包含多个不同状态，请确认后再修改 ")
                ]
              ),
              _c(
                "el-form",
                {
                  ref: "batchForm",
                  attrs: {
                    model: _vm.batchForm,
                    size: "small",
                    rules: _vm.batchRules,
                    "label-width": "170px"
                  }
                },
                [
                  _vm.command == "b"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "问题状态", prop: "queStatus" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "el-select--maia",
                              attrs: { filterable: "", size: "small" },
                              model: {
                                value: _vm.batchForm.queStatus,
                                callback: function($$v) {
                                  _vm.$set(_vm.batchForm, "queStatus", $$v)
                                },
                                expression: "batchForm.queStatus"
                              }
                            },
                            _vm._l(_vm.dicArray.statusList, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.cnname, value: item.id }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.command == "c"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "修改方", prop: "modifyParty" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "el-select--maia",
                              attrs: { filterable: "", size: "small" },
                              model: {
                                value: _vm.batchForm.modifyParty,
                                callback: function($$v) {
                                  _vm.$set(_vm.batchForm, "modifyParty", $$v)
                                },
                                expression: "batchForm.modifyParty"
                              }
                            },
                            _vm._l(_vm.dicArray.modifyPartyList, function(
                              item
                            ) {
                              return _c("el-option", {
                                key: item.depID,
                                attrs: {
                                  label: item.depName,
                                  value: item.depID
                                }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.command == "d"
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "完成日期",
                            prop: "requireFinishTime"
                          }
                        },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              type: "date",
                              "value-format": "yyyy-MM-dd",
                              placeholder: "请选择日期"
                            },
                            model: {
                              value: _vm.batchForm.requireFinishTime,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.batchForm,
                                  "requireFinishTime",
                                  $$v
                                )
                              },
                              expression: "batchForm.requireFinishTime"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "范围", prop: "type" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.batchForm.type,
                            callback: function($$v) {
                              _vm.$set(_vm.batchForm, "type", $$v)
                            },
                            expression: "batchForm.type"
                          }
                        },
                        [
                          _c("el-radio", { attrs: { label: "0" } }, [
                            _vm._v("目前选中")
                          ]),
                          _c("el-radio", { attrs: { label: "1" } }, [
                            _vm._v("当前页")
                          ]),
                          _vm.command == "a"
                            ? _c("el-radio", { attrs: { label: "2" } }, [
                                _vm._v("全部")
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.cancelBatch } },
                [_vm._v("取 消")]
              ),
              !_vm.sureDisabled
                ? _c(
                    "el-button",
                    {
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.sureBatch }
                    },
                    [_vm._v("确 定")]
                  )
                : _c(
                    "el-button",
                    { attrs: { size: "small", type: "info", disabled: "" } },
                    [_vm._v("数据生成中...")]
                  )
            ],
            1
          )
        ]
      ),
      _c("issueStatistical", {
        ref: "issueStatistical",
        attrs: { isNewList: _vm.isNewList }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }