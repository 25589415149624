var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "mainPage" } },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "div",
                { staticClass: "mainBody" },
                [
                  _c(
                    "el-tabs",
                    {
                      staticClass: "el-tabs--maiaUnderline",
                      on: { "tab-click": _vm.handleClick },
                      model: {
                        value: _vm.activeName,
                        callback: function($$v) {
                          _vm.activeName = $$v
                        },
                        expression: "activeName"
                      }
                    },
                    _vm._l(_vm.navList, function(item, index) {
                      return _c("el-tab-pane", {
                        key: index,
                        attrs: { label: item, name: index + "" }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mainHeader" },
                [
                  _vm.activeName == "0"
                    ? _c("checkLabel2D", {
                        ref: "checkLabel2D",
                        attrs: { isNewList: _vm.isNewList },
                        on: {
                          getDetail: _vm.getDetailMessage,
                          getDic: _vm.getDicArray,
                          rebackList: _vm.rebackList,
                          closeDetail: _vm.closePane
                        }
                      })
                    : _vm.activeName == "1"
                    ? _c("checkLabel3D", {
                        ref: "checkLabel3D",
                        attrs: { isNewList: _vm.isNewList },
                        on: {
                          getDetail: _vm.getDetailMessage,
                          getDic: _vm.getDicArray,
                          rebackList: _vm.rebackList,
                          closeDetail: _vm.closePane
                        }
                      })
                    : _vm.activeName == "2"
                    ? _c("checkLabelDOC", {
                        ref: "checkLabelDOC",
                        attrs: { isNewList: _vm.isNewList },
                        on: {
                          getDetail: _vm.getDetailMessage,
                          rebackList: _vm.rebackList,
                          closeDetail: _vm.closePane
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm.detailPane
                ? _c("div", { staticClass: "detail" }, [
                    _c(
                      "div",
                      { staticClass: "title" },
                      [
                        _c(
                          "el-tabs",
                          {
                            staticClass: "el-tabs--maiaUnderline",
                            on: { "tab-click": _vm.detailClick },
                            model: {
                              value: _vm.activeNav,
                              callback: function($$v) {
                                _vm.activeNav = $$v
                              },
                              expression: "activeNav"
                            }
                          },
                          _vm._l(_vm.detailList, function(item, index) {
                            return _c("el-tab-pane", {
                              key: index,
                              attrs: { label: item, name: index + "" }
                            })
                          }),
                          1
                        ),
                        _c("i", {
                          staticClass: "el-icon-close closeIcon",
                          on: { click: _vm.closePane }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "datailShow" },
                      [
                        _vm.activeNav == "0"
                          ? _c("issueDetail", {
                              ref: "showDetail",
                              attrs: {
                                isNewList: _vm.isNewList,
                                type: _vm.detailType,
                                id: _vm.detailId,
                                dicArray: _vm.dicData,
                                fileId: _vm.fileId
                              },
                              on: {
                                changeDetail: _vm.resetGetData,
                                openBigImg: _vm.openImgDialog
                              }
                            })
                          : _vm.activeNav == "1"
                          ? _c("reply", {
                              ref: "reply",
                              attrs: {
                                type: _vm.detailType,
                                id: _vm.detailId,
                                isNewList: _vm.isNewList
                              },
                              on: { resetList: _vm.resetList }
                            })
                          : _vm.activeNav == "2"
                          ? _c("record", {
                              ref: "record",
                              attrs: {
                                type: _vm.detailType,
                                id: _vm.detailId,
                                isNewList: _vm.isNewList
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _c(
                "el-dialog",
                {
                  staticClass: "el-dialog--maia",
                  attrs: {
                    title: "问题详情",
                    visible: _vm.dialogVisible,
                    width: "870px"
                  },
                  on: {
                    "update:visible": function($event) {
                      _vm.dialogVisible = $event
                    }
                  }
                },
                [
                  _vm.imgList.length > 0
                    ? _c("div", { staticClass: "maia-content" }, [
                        _vm.isNewList
                          ? _c("div", { staticClass: "left" }, [
                              _c("img", {
                                staticClass: "viewImg",
                                attrs: {
                                  src: _vm.imgList[_vm.imgIndex],
                                  alt: ""
                                }
                              }),
                              _c(
                                "div",
                                { staticClass: "img" },
                                _vm._l(_vm.imgList, function(img, index) {
                                  return _c("img", {
                                    key: index,
                                    attrs: { src: img, alt: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.amplificationView(index)
                                      }
                                    }
                                  })
                                }),
                                0
                              )
                            ])
                          : _c("div", { staticClass: "left" }, [
                              _c("img", {
                                staticClass: "viewImg",
                                attrs: {
                                  src: _vm.imgList[_vm.imgIndex].url,
                                  alt: ""
                                }
                              }),
                              _c(
                                "div",
                                { staticClass: "img" },
                                _vm._l(_vm.imgList, function(img, index) {
                                  return _c("img", {
                                    key: index,
                                    attrs: { src: img.thumbnailUrl, alt: "" },
                                    on: {
                                      click: function($event) {
                                        return _vm.amplificationView(index)
                                      }
                                    }
                                  })
                                }),
                                0
                              )
                            ]),
                        _c(
                          "div",
                          { staticClass: "right" },
                          [
                            _c(
                              "el-form",
                              {
                                key: "detailData",
                                ref: "detailData",
                                attrs: {
                                  model: _vm.detailData,
                                  size: "mini",
                                  "label-width": "95px"
                                }
                              },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "问题状态:" } },
                                  [_vm._v(_vm._s(_vm.detailData.queStatus))]
                                ),
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "问题描述:" } },
                                  [_vm._v(_vm._s(_vm.detailData.queDesc))]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    : _vm._e()
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }